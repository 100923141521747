<template>
  <div class="d-flex justify-content-between">
    <div class="d-flex align-items-baseline text-info text-lighten-2">
      <p>{{ parseLabel(item.label) }}</p>
      <feather-icon
        v-if="item.helperText"
        v-b-tooltip.hover.top="item.helperText"
        variant="outline-primary"
        size="14"
        icon="InfoIcon"
        class="ml-2 text-info text-lighten-2"
      />
    </div>
    <img
      class="mb-1"
      src="@/assets/images/icons/arrow-right-extended.svg"
    >
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    parseLabel(label) {
      if (label.trim() === 'Educations') return 'Education'
      return label
    },
  },
}
</script>
