<template>
  <div>
    <b-card
      class="mx-auto"
      style="max-width:800px; margin-bottom: 50px;"
    >
      <slot name="header">
        <h4 class="mt-3_5 mb-4_5">
          Map LinkedIn data to your CRM {{ tileProp }}
        </h4>
      </slot>

      <div
        class="field-banner text-center bg-primary bg-lighten-3 bg-cover"
        :style="`background-image:url(${bannerBackground})`"
      >
        <img
          :src="fieldsIllustration"
          width="70%"
          class="mt-4_5"
        >
      </div>

      <p class="mt-9 mb-5 font-small-3 text-info text-lighten-2">
        Surfe can extract multiple pieces of information from LinkedIn.<br>
        Let's make sure they are sent to the right place in your CRM.
      </p>

      <b-tabs
        v-if="contactFields.items"
        pills
        nav-class="mb-12"
      >
        <b-tab
          v-if="enabledFields.contactEnabled"
          title="Contact"
          active
        >
          <div>
            <b-table
              borderless
              fixed
              :items="contactFields.items"
              :fields="contactFields.fields"
            >
              <template #top-row="{ columns }">
                <!-- Row grouping workaround; https://github.com/bootstrap-vue/bootstrap-vue/issues/2889#issuecomment-475428931 -->
                <td :colspan="columns">
                  <h6 class="text-info text-lighten-2">
                    {{ contactFields.items[0].category.title }}
                  </h6>
                </td>
              </template>
              <template #row-details="{ item }">
                <h6 class="text-info text-lighten-2">
                  {{ item.nextCategory }}
                  <feather-icon
                    v-if="item.nextCategory === 'Enriched Phone'"
                    v-b-tooltip.hover="'Mapping is not required, if mapped CRM has phone data from connected enrichment tools'"
                    variant="outline-primary"
                    size="14"
                    icon="InfoIcon"
                    class="ml-2 text-info text-lighten-2"
                  />
                  <feather-icon
                    v-if="item.nextCategory === 'Enriched Email'"
                    v-b-tooltip.hover="'Mapping is not required, if mapped CRM has email data from connected enrichment tools'"
                    variant="outline-primary"
                    size="14"
                    icon="InfoIcon"
                    class="ml-2 text-info text-lighten-2"
                  />
                </h6>
              </template>
              <template #cell(linkedin)="data">
                <FieldLabel :item="data.item" />
              </template>
              <template #cell(crm)="data">
                <b-form-group>
                  <v-select
                    v-model="data.item[data.field.label]"
                    label="label"
                    :options="availableFields('contact', data.item[data.field.label] && data.item[data.field.label].mapping)"
                    :disabled="isFieldDisabled()"
                    :clearable="!!data.item[data.field.label] && !!data.item[data.field.label].label"
                    @input="updateFields(data.item.linkedin, data.item[data.field.label])"
                  />
                </b-form-group>
              </template>
            </b-table>
          </div>
        </b-tab>

        <b-tab
          v-if="enabledFields.leadEnabled"
          title="Lead"
        >
          <div>
            <b-table
              borderless
              fixed
              :items="leadFields.items"
              :fields="leadFields.fields"
            >
              <template #top-row="{ columns }">
                <!-- Row grouping workaround; https://github.com/bootstrap-vue/bootstrap-vue/issues/2889#issuecomment-475428931 -->
                <td :colspan="columns">
                  <h6 class="text-info text-lighten-2">
                    {{ leadFields.items[0].category.title }}
                  </h6>
                </td>
              </template>
              <template #row-details="{ item }">
                <h6 class="text-info text-lighten-2">
                  {{ item.nextCategory }}
                </h6>
              </template>
              <template #cell(linkedin)="data">
                <FieldLabel :item="data.item" />
              </template>
              <template #cell(crm)="data">
                <b-form-group>
                  <v-select
                    v-model="data.item[data.field.label]"
                    label="label"
                    :options="availableFields('lead', data.item[data.field.label] && data.item[data.field.label].mapping)"
                    :disabled="isFieldDisabled()"
                    :clearable="!!data.item[data.field.label] && !!data.item[data.field.label].label"
                    @input="updateFields(data.item.linkedin, data.item[data.field.label])"
                  />
                </b-form-group>
              </template>
            </b-table>
          </div>
        </b-tab>

        <b-tab
          v-if="enabledFields.orgEnabled"
          title="Company"
        >
          <div>
            <b-table
              borderless
              fixed
              :items="orgFields.items"
              :fields="orgFields.fields"
            >
              <template #top-row="{ columns }">
                <!-- Row grouping workaround; https://github.com/bootstrap-vue/bootstrap-vue/issues/2889#issuecomment-475428931 -->
                <td :colspan="columns">
                  <h6 class="text-info text-lighten-2">
                    {{ orgFields.items[0].category.title }}
                  </h6>
                </td>
              </template>
              <template #row-details="{ item }">
                <h6 class="text-info text-lighten-2">
                  {{ item.nextCategory }}
                </h6>
              </template>
              <template #cell(linkedin)="data">
                <FieldLabel :item="data.item" />
              </template>
              <template #cell(crm)="data">
                <b-form-group>
                  <v-select
                    v-model="data.item[data.field.label]"
                    label="label"
                    :options="availableFields('org', data.item[data.field.label] && data.item[data.field.label].mapping)"
                    :disabled="isFieldDisabled()"
                    :clearable="!!data.item[data.field.label] && !!data.item[data.field.label].label"
                    @input="updateFields(data.item.linkedin, data.item[data.field.label])"
                  />
                </b-form-group>
              </template>
            </b-table>
          </div>
        </b-tab>
      </b-tabs>

      <div
        v-else
        class="text-center"
      >
        <b-spinner
          v-if="true"
          variant="primary"
        />
      </div>
    </b-card>
    <FieldsMappingLog />
  </div>
</template>

<script>
/* eslint-disable global-require */

import {
  BCard,
  BTabs,
  BTab,
  BFormGroup,
  BSpinner,
  BTable,
  VBTooltip,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import { mapState } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FieldsMappingLog from '@/views/features/components/FieldsMappingLog.vue'
import { trackEvent } from '@core/utils/tracking'
import FieldLabel from './components/FieldLabel.vue'

export default {
  components: {
    FieldsMappingLog,
    BCard,
    BTabs,
    BTab,
    BFormGroup,
    vSelect,
    BSpinner,
    BTable,
    FieldLabel,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      bannerBackground: require('@/assets/images/pattern.svg'),
      fieldsIllustration: require('@/assets/images/fields_illustration.png'),
    }
  },
  computed: {
    ...mapState({
      account: state => state.api.account,
      tileProp: state => (state.api.account.crm === 'HUBSPOT' ? 'properties' : 'fields'),
      contactFields(state) {
        const fields = (state.api.crmFields.linkedinToCRM || {}).contact
        return this.getTableContent(fields)
      },

      leadFields(state) {
        const fields = (state.api.crmFields.linkedinToCRM || {}).lead
        return this.getTableContent(fields)
      },

      orgFields(state) {
        const fields = (state.api.crmFields.linkedinToCRM || {}).org
        return this.getTableContent(fields)
      },

      enabledFields(state) {
        return {
          leadEnabled: (state.api.crmFields.availableFields || {}).leadEnabled,
          contactEnabled: (state.api.crmFields.availableFields || {}).contactEnabled,
          orgEnabled: (state.api.crmFields.availableFields || {}).orgEnabled,
        }
      },
    }),
  },
  async mounted() {
    await this.$store.dispatch('api/loadCRMFields')
    await this.$store.dispatch('api/loadFieldsMappingLogs')
  },
  methods: {
    availableFields(t, fieldMapping) {
      const { crmFields } = this.$store.state.api
      const allAvailableFields = this.$store.state.api.crmFields.availableFields[t]
      if (!allAvailableFields) return {}

      const isListed = f => {
        if (f.readonly) return false

        // Ignore disallowed mappings
        if (fieldMapping && (fieldMapping.disallowed || []).includes(f.id)) return false

        // Ignore fields with type not allowed
        if (fieldMapping && !(fieldMapping.allowedTypes).includes(f.type)) return false

        // Remove already selected fields
        const linkedinFields = JSON.parse([JSON.stringify(crmFields.linkedinToCRM)])
        return !Object.entries(linkedinFields[t]).find(entry => {
          if (entry.length === 0 || !f) return false
          return entry[1].id === f.id || entry[1].label === f.label
        })
      }

      return allAvailableFields
        .filter(f => isListed(f))
        .sort((a, b) => a.label.localeCompare(b.label))
    },

    isFieldDisabled() {
      return !this.account.isAdmin && this.account.settings.adminOnlyFieldMapping
    },

    getLabel(key) {
      if (!key) return
      const keyMap = { headline: 'Job Function', phone: 'phone', secondphone: 'Mobile Phone' }
      return keyMap[key?.toLowerCase()] || this.formatKey(key)
    },

    getTableContent(currentFields) {
      const fields = currentFields
      const currentCRM = this.$store.state.api.account.crm
      const tableFields = ['linkedin', { key: 'crm', label: currentCRM }]

      if (!fields) return { fields: tableFields, items: [] }

      Object.keys(fields).forEach(f => {
        if (fields[f].mapping && !!fields[f].mapping.default) fields[f] = { label: fields[f].mapping.default, mapping: fields[f].mapping }
      })

      const items = Object.entries(fields).reduce(
        (total, [key, item]) => [
          ...total,
          {
            linkedin: key.charAt(0).toUpperCase() + key.slice(1),
            label: this.getLabel(key), // Special case for headline
            [currentCRM]: item,
            helperText: item.mapping.description,
            category: item.mapping.category,
          },
        ],
        [],
      ).sort(
        ((a, b) => a.category.orderId - b.category.orderId),
      )

      // Row grouping workaround: https://github.com/bootstrap-vue/bootstrap-vue/issues/2889#issuecomment-475428931
      const groupableItems = items.map((it, index) => {
        if (items[index + 1] !== undefined && it.category.title !== items[index + 1].category.title) {
          return { ...it, nextCategory: items[index + 1].category.title, _showDetails: true }
        }
        return it
      })

      return {
        fields: tableFields,
        items: groupableItems,
      }
    },

    async updateFields(field, val) {
      try {
        const contact = this.formatItems(this.contactFields.items)
        const lead = this.enabledFields.leadEnabled
          ? this.formatItems(this.leadFields.items)
          : {}
        const org = this.formatItems(this.orgFields.items)

        await this.$store.dispatch('api/updateCRMFields', {
          contact,
          lead,
          org,
        })

        if (val) {
          await trackEvent({ type: 'dashboard-fields_mapping-added', props: { field } })
        } else {
          await trackEvent({ type: 'dashboard-fields_mapping-removed', props: { field } })
        }
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Fields mapping updated',
            icon: 'SaveIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'An error occurred',
            text: error?.response?.data?.message ?? null,
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        }, { timeout: false })
        // Invalid mapping?
        if (
          error.response
          && error.response.data.message === 'Invalid fields mapping'
        ) {
          this.$swal({
            title: 'Invalid field mapping',
            text:
              'Your current CRM field mapping seems incorrect... Please check the fields types and try again.',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
        throw error
      }
    },

    formatKey(key) {
      const splitKey = (key.charAt(0).toUpperCase() + key.slice(1)).split(
        /(?=[A-Z])/,
      )
      splitKey.unshift('')
      return splitKey.reduce(
        (s, current) => s + current + (current.length > 1 ? ' ' : ''),
      )
    },

    formatItems(items) {
      const currentCRM = this.$store.state.api.account.crm
      return items.reduce((total, current) => {
        const key = current?.linkedin?.toLowerCase()
        const crm = current[currentCRM]
        const field = crm?.id ? crm.id : ''
        return {
          ...total,
          [key]: field,
        }
      }, {})
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.field-banner {
  margin-left: - $card-spacer-x;
  margin-right: - $card-spacer-x;
}
</style>
