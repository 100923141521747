<template>
  <div class="pb-7">
    <div v-if="fieldsMappingLogs.length > 0">
      <h4>
        Field Mapping Logs
      </h4>
      <div
        class="match-height mx-auto overflow-auto max-height-collapse"
      >
        <app-collapse
          accordion
          type="margin"
        >
          <div
            v-for="(logsData, i) in fieldsMappingLogs"
            :key="i"
          >
            <div
              v-for="(log, j) in logsData.data"
              :key="j + log.createdAt"
            >
              <app-collapse-item
                :title="title(logsData.performedByEmail, log)"
                title-size-class="custom-title-size"
              >
                <p>{{ logDescription(log) }}</p>
                <p>Change time: {{ date(logsData.createdAt) }}</p>
              </app-collapse-item>
            </div>
          </div>
        </app-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
  },
  computed: {
    ...mapState({
      fieldsMappingLogs: state => state.api.fieldsMappingLogs.map(x => ({
        ...x,
        performedByEmail: state.api.users.find(y => y.id === x.performedBy)?.email,
      })),
      users: state => state.api.users,
      availableFields: state => state.api.crmFields.availableFields,
    }),
  },
  methods: {
    title(email, log) {
      if (log.path.length < 3) return ''
      return `User (${email}) made changes in: ${log.path[1]} -> ${log.path[2]}`
    },
    date(createdAt) {
      return new Date(createdAt).toLocaleDateString(
        undefined,
        {
          weekday: 'long',
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        },
      )
    },
    logDescription(log) {
      if (log.path.length < 3) return ''
      return `Changed ${log.path[2]} mapping in ${log.path[1]} from ${this.findFieldName(log.from, log.path[1])} to ${this.findFieldName(log.to, log.path[1])}`
    },
    findFieldName(fieldId, fieldGroup) {
      if (fieldId === '') { return '<empty>' }
      const fields = this.availableFields[fieldGroup.toLowerCase()]
      const name = fields.find(x => x.id === fieldId)?.label
      return name ?? fieldId
    },
  },
}
</script>

<style>
.custom-title-size {
  font-size: 0.9rem !important;
}

.max-height-collapse {
  max-height: 30vh;
}
</style>
