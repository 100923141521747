var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"mx-auto",staticStyle:{"max-width":"800px","margin-bottom":"50px"}},[_vm._t("header",[_c('h4',{staticClass:"mt-3_5 mb-4_5"},[_vm._v(" Map LinkedIn data to your CRM "+_vm._s(_vm.tileProp)+" ")])]),_c('div',{staticClass:"field-banner text-center bg-primary bg-lighten-3 bg-cover",style:(("background-image:url(" + _vm.bannerBackground + ")"))},[_c('img',{staticClass:"mt-4_5",attrs:{"src":_vm.fieldsIllustration,"width":"70%"}})]),_c('p',{staticClass:"mt-9 mb-5 font-small-3 text-info text-lighten-2"},[_vm._v(" Surfe can extract multiple pieces of information from LinkedIn."),_c('br'),_vm._v(" Let's make sure they are sent to the right place in your CRM. ")]),(_vm.contactFields.items)?_c('b-tabs',{attrs:{"pills":"","nav-class":"mb-12"}},[(_vm.enabledFields.contactEnabled)?_c('b-tab',{attrs:{"title":"Contact","active":""}},[_c('div',[_c('b-table',{attrs:{"borderless":"","fixed":"","items":_vm.contactFields.items,"fields":_vm.contactFields.fields},scopedSlots:_vm._u([{key:"top-row",fn:function(ref){
var columns = ref.columns;
return [_c('td',{attrs:{"colspan":columns}},[_c('h6',{staticClass:"text-info text-lighten-2"},[_vm._v(" "+_vm._s(_vm.contactFields.items[0].category.title)+" ")])])]}},{key:"row-details",fn:function(ref){
var item = ref.item;
return [_c('h6',{staticClass:"text-info text-lighten-2"},[_vm._v(" "+_vm._s(item.nextCategory)+" "),(item.nextCategory === 'Enriched Phone')?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Mapping is not required, if mapped CRM has phone data from connected enrichment tools'),expression:"'Mapping is not required, if mapped CRM has phone data from connected enrichment tools'",modifiers:{"hover":true}}],staticClass:"ml-2 text-info text-lighten-2",attrs:{"variant":"outline-primary","size":"14","icon":"InfoIcon"}}):_vm._e(),(item.nextCategory === 'Enriched Email')?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Mapping is not required, if mapped CRM has email data from connected enrichment tools'),expression:"'Mapping is not required, if mapped CRM has email data from connected enrichment tools'",modifiers:{"hover":true}}],staticClass:"ml-2 text-info text-lighten-2",attrs:{"variant":"outline-primary","size":"14","icon":"InfoIcon"}}):_vm._e()],1)]}},{key:"cell(linkedin)",fn:function(data){return [_c('FieldLabel',{attrs:{"item":data.item}})]}},{key:"cell(crm)",fn:function(data){return [_c('b-form-group',[_c('v-select',{attrs:{"label":"label","options":_vm.availableFields('contact', data.item[data.field.label] && data.item[data.field.label].mapping),"disabled":_vm.isFieldDisabled(),"clearable":!!data.item[data.field.label] && !!data.item[data.field.label].label},on:{"input":function($event){return _vm.updateFields(data.item.linkedin, data.item[data.field.label])}},model:{value:(data.item[data.field.label]),callback:function ($$v) {_vm.$set(data.item, data.field.label, $$v)},expression:"data.item[data.field.label]"}})],1)]}}],null,false,1519546841)})],1)]):_vm._e(),(_vm.enabledFields.leadEnabled)?_c('b-tab',{attrs:{"title":"Lead"}},[_c('div',[_c('b-table',{attrs:{"borderless":"","fixed":"","items":_vm.leadFields.items,"fields":_vm.leadFields.fields},scopedSlots:_vm._u([{key:"top-row",fn:function(ref){
var columns = ref.columns;
return [_c('td',{attrs:{"colspan":columns}},[_c('h6',{staticClass:"text-info text-lighten-2"},[_vm._v(" "+_vm._s(_vm.leadFields.items[0].category.title)+" ")])])]}},{key:"row-details",fn:function(ref){
var item = ref.item;
return [_c('h6',{staticClass:"text-info text-lighten-2"},[_vm._v(" "+_vm._s(item.nextCategory)+" ")])]}},{key:"cell(linkedin)",fn:function(data){return [_c('FieldLabel',{attrs:{"item":data.item}})]}},{key:"cell(crm)",fn:function(data){return [_c('b-form-group',[_c('v-select',{attrs:{"label":"label","options":_vm.availableFields('lead', data.item[data.field.label] && data.item[data.field.label].mapping),"disabled":_vm.isFieldDisabled(),"clearable":!!data.item[data.field.label] && !!data.item[data.field.label].label},on:{"input":function($event){return _vm.updateFields(data.item.linkedin, data.item[data.field.label])}},model:{value:(data.item[data.field.label]),callback:function ($$v) {_vm.$set(data.item, data.field.label, $$v)},expression:"data.item[data.field.label]"}})],1)]}}],null,false,2780545364)})],1)]):_vm._e(),(_vm.enabledFields.orgEnabled)?_c('b-tab',{attrs:{"title":"Company"}},[_c('div',[_c('b-table',{attrs:{"borderless":"","fixed":"","items":_vm.orgFields.items,"fields":_vm.orgFields.fields},scopedSlots:_vm._u([{key:"top-row",fn:function(ref){
var columns = ref.columns;
return [_c('td',{attrs:{"colspan":columns}},[_c('h6',{staticClass:"text-info text-lighten-2"},[_vm._v(" "+_vm._s(_vm.orgFields.items[0].category.title)+" ")])])]}},{key:"row-details",fn:function(ref){
var item = ref.item;
return [_c('h6',{staticClass:"text-info text-lighten-2"},[_vm._v(" "+_vm._s(item.nextCategory)+" ")])]}},{key:"cell(linkedin)",fn:function(data){return [_c('FieldLabel',{attrs:{"item":data.item}})]}},{key:"cell(crm)",fn:function(data){return [_c('b-form-group',[_c('v-select',{attrs:{"label":"label","options":_vm.availableFields('org', data.item[data.field.label] && data.item[data.field.label].mapping),"disabled":_vm.isFieldDisabled(),"clearable":!!data.item[data.field.label] && !!data.item[data.field.label].label},on:{"input":function($event){return _vm.updateFields(data.item.linkedin, data.item[data.field.label])}},model:{value:(data.item[data.field.label]),callback:function ($$v) {_vm.$set(data.item, data.field.label, $$v)},expression:"data.item[data.field.label]"}})],1)]}}],null,false,1214359604)})],1)]):_vm._e()],1):_c('div',{staticClass:"text-center"},[(true)?_c('b-spinner',{attrs:{"variant":"primary"}}):_vm._e()],1)],2),_c('FieldsMappingLog')],1)}
var staticRenderFns = []

export { render, staticRenderFns }